import type { RefObject, ReactNode } from 'react';
import React, {
	memo,
	useState,
	useRef,
	useCallback,
	useMemo,
	useEffect,
	Fragment,
	useContext,
} from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';

import { layers } from '@atlaskit/theme/constants';
import Portal from '@atlaskit/portal';

import {
	TEMPLATE_PREVIEW_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { TransparentErrorBoundary, Attribution } from '@confluence/error-boundary';

import { PreviewAnalyticsProvider, HoverAnalytics } from './PreviewAnalytics';
import { PreviewFrame } from './PreviewFrame';
import { TemplatePreview } from './TemplatePreview';
import {
	calculatePreviewPosition,
	getTemplateId,
	type TemplatePreviewTemplate,
} from './templatePreviewHelpers';

const HIDE_TIMEOUT = 250;
const RENDER_DEBOUNCE_TIME = 80;

type ContainerProps = {
	top: number;
	left: number;
	isVisible: boolean;
	isEditorView: boolean;
};

type RenderProps = {
	showPreview: (
		template: TemplatePreviewTemplate,
		relativeCoordinates: DOMRect,
		templateIndex: number,
	) => void;
	hidePreview: () => void;
};

type Props = {
	spaceKey: string;
	templates: TemplatePreviewTemplate[];
	numPreviewsToFetchAtOnce?: number;
	hasDynamicPositioning?: boolean;
	isEditorView?: boolean;
	hoverSource: string;
	children: (props: RenderProps) => ReactNode;
	searchQuery?: string;
	renderedCategoryIds?: (string | null)[];
	isBottomPositioned?: boolean;
	isHorizontallyCentered?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewContainer = styled.div<ContainerProps>({
	/* eslint-disable @atlaskit/design-system/ensure-design-token-usage/preview */
	// these are not hardcoded values so therefore can't be tokenized
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: (props) => `${props.top}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: (props) => `${props.left}px`,
	/* eslint-enable @atlaskit/design-system/ensure-design-token-usage/preview */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.isVisible ? 1 : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: (props) => (props.isVisible ? 'auto' : 'none'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: (props) => (props.isEditorView ? 'fixed' : 'absolute'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transition: (props) => (props.isVisible ? 'all 100ms linear' : 'none'),
});

const PopupPreviewComponent = ({
	spaceKey,
	templates,
	numPreviewsToFetchAtOnce,
	hasDynamicPositioning = false,
	isEditorView = true,
	hoverSource,
	searchQuery,
	renderedCategoryIds,
	children,
	isBottomPositioned,
	isHorizontallyCentered,
}: Props) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [currentTemplate, setCurrentTemplate] = useState<TemplatePreviewTemplate | undefined>();
	const [position, setPosition] = useState<{ top: number; left: number }>({
		top: -1000,
		left: -1000,
	});
	const [templateIndex, setTemplateIndex] = useState<number>(-1);

	const previewRef = useRef<HTMLDivElement>();
	const hideTimeout = useRef<number | null>(null);
	const currentTemplateRef = useRef<TemplatePreviewTemplate | undefined>();

	const debouncedSetTemplateAndIndex = useMemo(
		() =>
			debounce(async (template: TemplatePreviewTemplate, templateIndex: number) => {
				setTemplateIndex(templateIndex);
				setCurrentTemplate(template);
				currentTemplateRef.current = template;
			}, RENDER_DEBOUNCE_TIME),
		[],
	);

	const resumePreview = useCallback(() => {
		if (hideTimeout.current) {
			clearTimeout(hideTimeout.current);
			hideTimeout.current = null;
		}
	}, []);

	const showPreview = useCallback(
		async (
			template: TemplatePreviewTemplate,
			relativeCoordinates: DOMRect,
			templateIndex: number,
		) => {
			if (!previewRef.current) {
				return;
			}

			resumePreview();

			// we use ref, not state (currentTemplate) here, since state should
			// be added as a dependency to useEffect
			// currentTemplate changes quite often, this would lead to
			// change of showPreview handler, and that would result
			// in re-render of component that consumes showPreview via render props
			if (
				currentTemplateRef.current &&
				getTemplateId(currentTemplateRef.current) === getTemplateId(template)
			) {
				experienceTracker.abort({
					name: TEMPLATE_PREVIEW_EXPERIENCE,
					reason: 'Template preview is already being displayed',
				});

				return;
			}

			const position = calculatePreviewPosition(
				previewRef.current.getBoundingClientRect(),
				relativeCoordinates,
				hasDynamicPositioning,
				isBottomPositioned,
				isHorizontallyCentered,
			);

			setPosition(position);
			await debouncedSetTemplateAndIndex(template, templateIndex);
		},
		[
			resumePreview,
			debouncedSetTemplateAndIndex,
			experienceTracker,
			hasDynamicPositioning,
			isBottomPositioned,
			isHorizontallyCentered,
		],
	);

	const hidePreview = useCallback(() => {
		if (hideTimeout.current) {
			return;
		}
		hideTimeout.current = window.setTimeout(() => {
			currentTemplateRef.current = undefined;
			setCurrentTemplate(undefined);
			hideTimeout.current = null;
			setPosition({ top: -1000, left: -1000 });
		}, HIDE_TIMEOUT);
	}, []);

	const renderProps = useMemo(
		() => ({
			showPreview,
			hidePreview,
		}),
		[showPreview, hidePreview],
	);

	// handles starring updates since the templates list gets updated but the order doesn't change nor do the previews
	useEffect(() => {
		if (templates.length - 1 < templateIndex) {
			// hide preview since the template no longer exists and has been unstarred
			// happens when the last template in the rendered list has been unstarred
			hidePreview();
		}
	}, [templates, hidePreview, templateIndex]);

	// prefetch first N template previews
	// when category or search changes
	useEffect(() => {
		if (templates.length && templateIndex !== -1) {
			setTemplateIndex(0);
		}

		// intentionally run this hook only when `category or searchquery` changes, not `templateIndex` or `templates`
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery, renderedCategoryIds]);

	useEffect(() => {
		window.addEventListener('resize', hidePreview);
		return () => window.removeEventListener('resize', hidePreview);
	}, [hidePreview]);

	return (
		<Fragment>
			<Portal zIndex={isEditorView ? layers.tooltip() : 13}>
				<TransparentErrorBoundary attribution={Attribution.TAILORED_EXPERIENCES}>
					<PreviewContainer
						ref={previewRef as RefObject<HTMLDivElement>}
						onMouseEnter={resumePreview}
						onMouseLeave={hidePreview}
						top={position.top}
						left={position.left}
						isVisible={!!currentTemplate}
						isEditorView={isEditorView}
						data-testid="template-preview-container"
						{...(!!currentTemplate ? {} : { 'aria-hidden': true })}
					>
						<PreviewAnalyticsProvider template={currentTemplate}>
							<HoverAnalytics source={hoverSource}>
								<PreviewFrame template={currentTemplate} isBottomPositioned={isBottomPositioned}>
									<TemplatePreview
										spaceKey={spaceKey}
										templates={templates}
										currentTemplateIndex={templateIndex}
										numPreviewsToFetchAtOnce={numPreviewsToFetchAtOnce}
										marginBottom={0}
										isScrollable
									/>
								</PreviewFrame>
							</HoverAnalytics>
						</PreviewAnalyticsProvider>
					</PreviewContainer>
				</TransparentErrorBoundary>
			</Portal>
			{children(renderProps)}
		</Fragment>
	);
};

export const PopupPreview = memo(PopupPreviewComponent);
