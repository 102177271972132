import type { ReactNode } from 'react';
import React, { useContext, useCallback, useRef } from 'react';

import { PreviewAnalyticsContext } from './PreviewAnalyticsProvider';

const TIME_SPENT_OVER_PREVIEW = 2 * 1000;

type Props = {
	children: ReactNode;
	source: string;
};

export const HoverAnalytics = ({ children, source }: Props) => {
	const { fireAnalyticsEventWithTemplateData } = useContext(PreviewAnalyticsContext);
	const timeout = useRef<number | null>(null);

	const handleMouseEnter = useCallback(() => {
		if (timeout.current) {
			return;
		}
		timeout.current = window.setTimeout(() => {
			fireAnalyticsEventWithTemplateData({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'hovered',
					actionSubject: 'templatePreview',
				},
			});
		}, TIME_SPENT_OVER_PREVIEW);
	}, [fireAnalyticsEventWithTemplateData, source]);

	const handleMouseLeave = useCallback(() => {
		if (timeout.current) {
			clearTimeout(timeout.current);
			timeout.current = null;
		}
	}, []);

	return (
		<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			{children}
		</div>
	);
};
